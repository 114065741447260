const React = require("react");

export const onInitialClientRender = () => {
  if (typeof window !== "undefined" && window.drift === undefined) {
    // Properly formatted IIFE
    (function () {
      var t;
      t = window.drift = window.driftt = window.driftt || [];
      if (!t.init) {
        t.invoked = !0;
        t.methods = [
          "identify",
          "config",
          "track",
          "reset",
          "debug",
          "show",
          "ping",
          "page",
          "hide",
          "off",
          "on",
        ];
        t.factory = function (e) {
          return function () {
            var n = Array.prototype.slice.call(arguments);
            n.unshift(e);
            t.push(n);
            return t;
          };
        };
        t.methods.forEach(function (e) {
          t[e] = t.factory(e);
        });
        t.load = function (t) {
          var e = 3e5,
            n = Math.ceil(new Date() / e) * e,
            o = document.createElement("script");
          o.type = "text/javascript";
          o.async = !0;
          o.crossorigin = "anonymous";
          o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
          var i = document.getElementsByTagName("script")[0];
          i.parentNode.insertBefore(o, i);
        };
      }
    })();

    // Load the Drift bot
    window.drift.SNIPPET_VERSION = "0.3.1";
    window.drift.load("dvzy9khhkeme");
    window.drift.on("ready", function () {
      window.dataLayer.push({
        event: "driftReady",
      });
    });
  }
};

export const onRouteUpdate = ({ location }) => {
  if (typeof window.drift !== "undefined") {
    // Ensure Drift tracks the page change
    window.drift.page();
  }
};
